import { ReactComponent as MMGLogo } from "./mmg-logo.svg";
import { ReactComponent as FilterIcon } from "./filter-icon.svg";
import { ReactComponent as Gearshift } from "./gearshift.svg";
import { ReactComponent as GasStation } from "./gas_station.svg";
import { ReactComponent as Calendar } from "./new_calendar.svg";
import { ReactComponent as Speed } from "./speed.svg";
import { ReactComponent as NotFoundIcon } from "./not-found.svg";
import { ReactComponent as InternalErrorIcon } from "./internal-error.svg";
import { ReactComponent as ImageIcon } from "./image-icon.svg";

export {
  MMGLogo,
  FilterIcon,
  Gearshift,
  GasStation,
  Calendar,
  Speed,
  NotFoundIcon,
  InternalErrorIcon,
  ImageIcon,
};
